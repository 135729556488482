import { useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutBaseProvider"
import React, { useState } from "react"
import { Button, Card, Modal, Stack, TextareaAutosize, Typography } from "@mui/material"
import AddWidgetModal from "domain/dashboard/DashboardLayout/AddWidgetModal"
import LoadingButton from "@mui/lab/LoadingButton"
import { LegacyDashboardConfigResponseDTO } from "generated/models"
import { DownloadButton } from "domain/dashboard/DashboardLayout/DownloadButton"

export const DashboardConfigurationToolActions = () => {
    const {
        readOnly,
        onConvertToLegacy,
        convertToLegacyIsPending,
        setFloat,
        convertLegacyToNew,
        convertLegacyToNewIsPending,
    } = useDashboardLayout()
    const [textareaValue, setTextareaValue] = useState("")
    const [isImportOpen, setIsImportOpen] = useState(false)
    const [importError, setImportError] = useState("")

    return (
        <Stack direction="row" gap={1} justifyContent="flex-end">
            {!readOnly && (
                <>
                    <AddWidgetModal />
                    <LoadingButton
                        variant="contained"
                        onClick={async () => {
                            setIsImportOpen(true)
                        }}
                        loading={convertLegacyToNewIsPending}
                    >
                        Import
                    </LoadingButton>
                    <Modal
                        open={isImportOpen}
                        onClose={() => {
                            setIsImportOpen(false)
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Card sx={{ px: 4, py: 2, minWidth: 640 }}>
                            <TextareaAutosize
                                maxRows={36}
                                style={{ width: "100%", height: "300px", maxHeight: "300px" }}
                                value={textareaValue}
                                onChange={(e) => setTextareaValue(e.target.value)}
                            />
                            <Stack direction="row" gap={1} justifyContent="flex-end">
                                {importError && <Typography color="error">{importError}</Typography>}
                                <Button variant="contained" onClick={() => setIsImportOpen(false)}>
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={async () => {
                                        try {
                                            const newDashboard = JSON.parse(
                                                textareaValue,
                                            ) as LegacyDashboardConfigResponseDTO
                                            /**
                                             * We have to toggle float before importing since a registered widget
                                             * moves away other widgets before they are ready to register
                                             */
                                            setFloat(true)
                                            convertLegacyToNew(newDashboard)
                                            setTimeout(() => setFloat(false), 400) // The duration of the animation
                                            setIsImportOpen(false)
                                        } catch (error) {
                                            if (error instanceof Error) {
                                                setImportError(error.message)
                                            }
                                        }
                                    }}
                                >
                                    Import
                                </Button>
                            </Stack>
                        </Card>
                    </Modal>
                    <LoadingButton variant="contained" onClick={onConvertToLegacy} loading={convertToLegacyIsPending}>
                        Download json
                    </LoadingButton>
                </>
            )}
            <DownloadButton />
        </Stack>
    )
}
