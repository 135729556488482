// MenuRenderer.tsx
import React, { Fragment } from "react"
import { List, useTheme } from "@mui/material"
import { MenuDTO, MenuNodeDTO } from "generated/models"
import { isMenuLeaf, isNode } from "shared/service/MenuUtil"
import { MenuLeafDTO } from "domain/types"

export interface MenuRendererProps {
    /** The current nesting level */
    level: number
    /** The menu items to render at this level */
    menuItems: MenuDTO[]
    /** Optional: whether the list should be dense */
    dense?: boolean
    /** If false, nodes will not render their children recursively */
    recursiveNodes?: boolean
    /**
     * Function to render a leaf item.
     * Receives the current level and the leaf item.
     */
    renderLeaf: (level: number, item: MenuLeafDTO) => React.ReactNode
    /**
     * Function to render a node.
     * Receives the current level, the node item, and the rendered children (if any).
     */
    renderNode: (level: number, item: MenuNodeDTO, children: React.ReactNode | null) => React.ReactNode
}

export const MenuRenderer: React.FC<MenuRendererProps> = ({
    level,
    menuItems,
    dense = false,
    recursiveNodes = true,
    renderLeaf,
    renderNode,
}) => {
    const theme = useTheme()

    return (
        <List
            dense={dense}
            className={`menu-list level-${level}`}
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
                position: level === 1 ? "relative" : "inherit",
                "&:after":
                    level > 0
                        ? {
                              content: "''",
                              position: "absolute",
                              left: "25px",
                              top: 0,
                              height: "100%",
                              width: "1px",
                              background: theme.palette.border.subtle,
                          }
                        : {},
            }}
        >
            {menuItems.map((item) => {
                if (isMenuLeaf(item)) {
                    return renderLeaf(level, item)
                } else if (isNode(item)) {
                    const children = recursiveNodes ? (
                        <MenuRenderer
                            level={level + 1}
                            menuItems={item.items}
                            dense={true}
                            recursiveNodes={recursiveNodes}
                            renderLeaf={renderLeaf}
                            renderNode={renderNode}
                        />
                    ) : null
                    return <Fragment key={item.path + "_node"}>{renderNode(level, item, children)}</Fragment>
                }
                return null
            })}
            {level === 1 && (
                <div
                    className="selected-leaf-indicator"
                    style={{
                        borderRadius: "5px",
                        zIndex: 1,
                        content: "''",
                        position: "absolute",
                        left: "23px",
                        top: "0",
                        height: "0px",
                        width: "5px",
                        background: theme.palette.primary.main,
                        transition: "all 0.5s ease-in-out",
                    }}
                />
            )}
        </List>
    )
}
