import { useQuery, UseQueryResult } from "@tanstack/react-query"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import type { DashboardDTO } from "generated/models"

export const useQueryDashboardsView = (
    uuid: string,
    placeholderData: DashboardDTO | undefined,
    enabled: boolean,
): UseQueryResult<DashboardDTO, Error> => {
    return useQuery({
        meta: {
            // This is used in the LoadingOverlay component to determine if all dashboard queries are loaded
            // and the loading overlay can be hidden.
            // Do not set the key to true if the query is disabled because the query will get the pending status
            // and the loading overlay will never be hidden.
            dashboard: enabled,
        },
        queryKey: ["/api/reporting/dashboards/view", uuid],
        queryFn: ({ signal }) => {
            return api.get(`${UrlService.getReportingServiceApiUrl()}/dashboards/view/${uuid}`, {
                signal,
            }) as Promise<DashboardDTO>
        },
        staleTime: Infinity, // https://tkdodo.eu/blog/react-query-and-forms
        placeholderData: placeholderData,
        enabled: enabled,
    })
}
