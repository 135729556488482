import React, { useEffect } from "react"
import { useMousePosition } from "./useMousePosition"
import { useMouseMoving } from "layout/MainLayout/Menu/CollapsedMenu/useMouseMoving"

type SafeAreaProps = {
    submenu: HTMLElement
}
export const SafeArea = ({ submenu }: SafeAreaProps) => {
    const SAFE_AREA_MOUSE_MOVING_TIMEOUT_MS = 500

    const { x: mouseX, y: mouseY } = useMousePosition()
    const { isMouseMovingRight } = useMouseMoving()
    const [isMouseMoving, setIsMouseMoving] = React.useState(false)

    useEffect(() => {
        setIsMouseMoving(true)
        const timeout = setTimeout(() => {
            setIsMouseMoving(false)
        }, SAFE_AREA_MOUSE_MOVING_TIMEOUT_MS)

        return () => {
            clearTimeout(timeout)
        }
    }, [mouseX, mouseY])

    const { height: submenuHeight, x: submenuX, y: submenuY } = submenu.getBoundingClientRect()

    const svgWidth = submenuX - mouseX + 4
    // Limit the height of the svg to 400px to avoid issues with switching to the next menu entry
    // because of the large svg blocking the mouse pointer
    const svgHeight = Math.min(submenuHeight, 400)

    // Show the safe area only when the mouse is moving to the right
    return isMouseMovingRight && isMouseMoving ? (
        <svg
            style={{
                position: "fixed",
                width: svgWidth,
                height: submenuHeight,
                pointerEvents: "none",
                zIndex: 2,
                top: submenuY,
                left: mouseX - 2,
            }}
            id="svg-safe-area"
        >
            {/* Safe Area */}
            <path
                pointerEvents="auto"
                // stroke="red"
                // strokeWidth="0.4"
                fill="rgba(0,0,0,0)"
                // prettier-ignore
                d={`M 0, ${mouseY-submenuY}
            L ${svgWidth},${svgHeight}
            L ${svgWidth},0 z`}
            ></path>
        </svg>
    ) : (
        <React.Fragment />
    )
}
