import { asDataColumnIdentifier, ColumnField } from "domain/dimension/service/DimensionService"
import { type ReportingReplaceTogglesDTO } from "generated/models"
import React, { createContext, PropsWithChildren, useContext, useState } from "react"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"

type ReportingToggle = keyof ReportingReplaceTogglesDTO

type ReportingToggleValue = {
    value: string
    options: Array<{
        name: string
        value: string
    }>
}

type StringOrColumnField = string | ColumnField

type ReportingToggleProviderType = {
    setToggle: (type: ReportingToggle, value: string) => void
    replaceIdentifier: (identifier: StringOrColumnField) => ColumnField
    kpi: ReportingToggleValue
    method: ReportingToggleValue
    dimension: ReportingToggleValue
}

const ReportingToggleContext = createContext<ReportingToggleProviderType | undefined>(undefined)

type ReportingToggleProviderProps = {
    reportingReplaceToggles?: ReportingReplaceTogglesDTO | undefined
}

export const ReportingToggleContextProvider = (props: PropsWithChildren<ReportingToggleProviderProps>) => {
    const { reportingReplaceToggles, children } = props
    const dimension = reportingReplaceToggles?.dimension
    const kpi = reportingReplaceToggles?.kpi
    const method = reportingReplaceToggles?.method

    const {
        dataDefinitions: { dimensions, metrics },
    } = useReportingConfigurationContext()
    const [toggles, setToggles] = useState({
        kpi: kpi?.replaceIdentifier,
        method: method?.replaceIdentifier,
        dimension: dimension?.replaceIdentifier,
    })

    const replaceIdentifier = (identifierOrColumn: StringOrColumnField) => {
        const column =
            identifierOrColumn instanceof ColumnField ? identifierOrColumn : ColumnField.recognize(identifierOrColumn)

        const dimensionIdentifier = dimensions.get(column.identifier)?.uniqueName
        let identifier: string | undefined
        if (dimensionIdentifier && dimension && toggles.dimension) {
            identifier = dimensionIdentifier.replace(dimension.replaceIdentifier, toggles.dimension)
        }
        const metricIdentifier = metrics.get(column.identifier)?.uniqueName
        if (!identifier && metricIdentifier && kpi && toggles.kpi && method && toggles.method) {
            identifier = metricIdentifier
                .replace(kpi.replaceIdentifier, toggles.kpi)
                .replace(method.replaceIdentifier, toggles.method)
        }

        if (identifier) {
            return new ColumnField(asDataColumnIdentifier(identifier), column.fieldType)
        } else {
            return column
        }
    }

    const setToggle = (type: ReportingToggle, value: string) => {
        setToggles((prev) => ({ ...prev, [type]: value }))
    }

    return (
        <ReportingToggleContext.Provider
            value={{
                setToggle,
                replaceIdentifier,
                kpi: {
                    value: toggles.kpi || "",
                    options: kpi?.replaceOptions || [],
                },
                method: {
                    value: toggles.method || "",
                    options: method?.replaceOptions || [],
                },
                dimension: {
                    value: toggles.dimension || "",
                    options: dimension?.replaceOptions || [],
                },
            }}
        >
            {children}
        </ReportingToggleContext.Provider>
    )
}

export const useDashboardActions = () => {
    const context = useContext(ReportingToggleContext)
    if (context === undefined) {
        throw new Error("useDashboardActions must be used within a DashboardActionsProvider")
    }
    return context
}

export const useReportingToggles = () => {
    const context = useContext(ReportingToggleContext)
    if (context === undefined) {
        throw new Error("useReportingToggleForm must be used within a ReportingToggleContextProvider")
    }
    return context
}

export function useReplacedIdentifiers(identifier: StringOrColumnField): ColumnField
export function useReplacedIdentifiers(
    identifiers: [StringOrColumnField, ...StringOrColumnField[]],
): [ColumnField, ...ColumnField[]]
export function useReplacedIdentifiers(identifiers: StringOrColumnField[]): ColumnField[]
export function useReplacedIdentifiers(identifiers: StringOrColumnField | StringOrColumnField[]) {
    const context = useContext(ReportingToggleContext)
    if (context === undefined) {
        throw new Error("useReplacedIdentifiers must be used within a ReportingToggleContextProvider")
    }
    if (Array.isArray(identifiers)) {
        return identifiers.map(context.replaceIdentifier)
    }
    return context.replaceIdentifier(identifiers)
}
