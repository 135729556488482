import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { DashboardDTO, LegacyDashboardConfigResponseDTO } from "generated/models"
import * as api from "shared/service"
import UrlUtil from "shared/util/UrlUtil"
import UrlService from "shared/service/url.service"

export const useMutationDashboardDTOToLegacy = (
    uuid: string,
    onSuccess: (legacyDashboard: LegacyDashboardConfigResponseDTO) => void,
): UseMutationResult<LegacyDashboardConfigResponseDTO, Error, DashboardDTO, unknown> => {
    return useMutation({
        mutationKey: ["/api/reporting/dashboards/convertToLegacy", uuid],
        mutationFn: (dashboard: DashboardDTO) => {
            return api.post(
                UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards/convertToLegacy"),
                dashboard,
                {
                    responseType: "blob",
                },
            ) as Promise<LegacyDashboardConfigResponseDTO>
        },
        onSuccess: onSuccess,
    })
}
