import { getWidgetFormFieldKey, WidgetForm } from "domain/dashboard/DashboardLayout/DashboardLayoutBaseProvider"
import { ContainerWidgetDTO, TabbedWidgetDTO, WidgetDTOTypeEnum } from "generated/models"
import { assertExhaustive } from "shared/util/TypeUtil"

export const removeFromWidgetForm = (widgetForm: WidgetForm, id: string) => {
    const widgetConfig = widgetForm.getFieldValue(getWidgetFormFieldKey(id))
    switch (widgetConfig.type) {
        // container and tabbed widgets need also to remove all child widgets
        case WidgetDTOTypeEnum.CONTAINER_WIDGET:
            ;(widgetConfig as ContainerWidgetDTO).settings.layout.forEach((child) =>
                removeFromWidgetForm(widgetForm, child.id),
            )
            break
        case WidgetDTOTypeEnum.TABBED_WIDGET:
            ;(widgetConfig as TabbedWidgetDTO).settings.tabs.forEach((tab) =>
                tab.layout.forEach((child) => removeFromWidgetForm(widgetForm, child.id)),
            )
            break
        case WidgetDTOTypeEnum.TEXT_WIDGET:
        case WidgetDTOTypeEnum.LINE_CHART_WIDGET:
        case WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET:
        case WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET:
        case WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET:
        case WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET:
        case WidgetDTOTypeEnum.METRIC_PERFORMANCE_CARD_WIDGET:
        case WidgetDTOTypeEnum.PIE_CHART_WIDGET:
        case WidgetDTOTypeEnum.PERIOD_WIDGET:
        case WidgetDTOTypeEnum.PREVIOUS_PERIOD_WIDGET:
        case WidgetDTOTypeEnum.METRIC_TOGGLE_WIDGET:
        case WidgetDTOTypeEnum.REPORTING_DIMENSION_TOGGLE_WIDGET:
        case WidgetDTOTypeEnum.REPORTING_METHOD_TOGGLE_WIDGET:
            break
        default:
            assertExhaustive(widgetConfig.type)
    }

    widgetForm.deleteField(getWidgetFormFieldKey(id))
}
