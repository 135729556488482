import { DashboardLayoutProvider } from "domain/dashboard/DashboardLayout/DashboardLayoutProvider"
import { Box, CircularProgress, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { log } from "shared/util/log"
import AddWidgetModal from "domain/dashboard/DashboardLayout/AddWidgetModal"
import { DashboardLayoutGrid } from "domain/dashboard/DashboardLayout/DashboardLayoutGrid"
import LoadingButton from "@mui/lab/LoadingButton"
import { DownloadButton } from "domain/dashboard/DashboardLayout/DownloadButton"
import { DashboardSettingsContextProvider } from "domain/dashboard/DashboardSettings/DashboardSettingsContext"
import { useDashboardLayout } from "./DashboardLayout/DashboardLayoutBaseProvider"

type DashboardPageProps = {
    uuid: string
}

const LoadingOverlay = () => {
    const queryClient = useQueryClient()
    const queryCache = queryClient.getQueryCache()
    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        let timeout: NodeJS.Timeout
        let hasLoaded = false
        const unsubscribe = queryCache.subscribe(() => {
            if (hasLoaded) return
            const queries = queryCache.getAll()
            const dashboardQueries = queries.filter((query) => query.meta?.["dashboard"])
            const allLoaded = dashboardQueries.every((query) => query.state.status !== "pending")
            clearTimeout(timeout)
            if (allLoaded) {
                timeout = setTimeout(() => {
                    hasLoaded = true
                    setHasLoaded(true)
                    log.debug("All dashboard queries loaded")
                    unsubscribe()
                }, 500)
            }
        })
        return () => {
            unsubscribe()
            clearTimeout(timeout)
        }
    }, [queryCache])

    if (hasLoaded) return null

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                right: -12,
                left: -12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                minHeight: "100vh",
                zIndex: 100,
            }}
        >
            <CircularProgress />
        </Box>
    )
}

export const DashboardPage = (props: DashboardPageProps) => {
    const { uuid } = props

    return (
        <Box position="relative">
            <DashboardSettingsContextProvider key={uuid} uuid={uuid}>
                <DashboardLayoutProvider>
                    <Stack gap={2}>
                        <DashboardLayoutActions />
                        <DashboardLayoutGrid />
                    </Stack>
                    <LoadingOverlay />
                </DashboardLayoutProvider>
            </DashboardSettingsContextProvider>
        </Box>
    )
}

const DashboardLayoutActions = () => {
    const { readOnly, submit, updateIsPending } = useDashboardLayout()

    return (
        <Stack direction="row" gap={1} justifyContent="flex-end">
            {!readOnly && (
                <>
                    <AddWidgetModal />
                    <LoadingButton variant="contained" onClick={submit} loading={updateIsPending}>
                        Save (WIP)
                    </LoadingButton>
                </>
            )}
            <DownloadButton />
        </Stack>
    )
}
