import { PageErrorMessage } from "domain/core/component/PageErrorMessage"
import { AppContextDTO, ContainerElementDTO, LayoutElementDTO } from "generated/models"
import { NewUiGenericPageMenuLeafDTO } from "generated/models"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { AdditionalFilterContextProvider } from "shared/component/layout/context/AdditionalFilterContext"
import LayoutRenderer from "shared/component/layout/renderers/LayoutRenderer"
import PageService from "shared/service/page.service"
import { log } from "shared/util/log"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"

export type GenericPageProps = {
    appContext?: AppContextDTO
    pageConfiguration: NewUiGenericPageMenuLeafDTO
}

type State = {
    userAuthorized?: boolean
    layoutConfig?: LayoutElementDTO
    isLoading: boolean
    loadPageConfigError?: boolean
}

const GenericPage: React.FunctionComponent<GenericPageProps> = (props: GenericPageProps): JSX.Element => {
    const [state, setState] = useState<State>({
        isLoading: false,
        userAuthorized: undefined,
        layoutConfig: undefined,
        loadPageConfigError: undefined,
    })

    const settingsAreValid = state.layoutConfig !== undefined
    const BODY_CLASS_NAME = "generic-page-body"

    const loadAndApplyPageConfig = (path: string, appContext: AppContextDTO) => {
        setState((prev) => {
            // show the spinner
            return { ...prev, isLoading: true }
        })

        PageService.loadPageConfig(props.pageConfiguration.layoutConfigUrl, appContext)
            .then((pageConfig) => {
                setState((prev) => {
                    return {
                        ...prev,
                        isLoading: false,
                        userAuthorized: true,
                        layoutConfig: {
                            ...pageConfig.layoutConfig,
                            // Add appContext and path to the identifier to make sure that the layout is reloaded only
                            // after the page has loaded.
                            identifier: (pageConfig.layoutConfig.identifier ?? "") + path + JSON.stringify(appContext),
                        },
                        loadPageConfigError: false,
                    }
                })
            })
            .catch((error) => {
                log.error("could not load page config: ", error)
                setState((prev) => {
                    return {
                        ...prev,
                        isLoading: false,
                        userAuthorized: error.httpStatus === "FORBIDDEN" ? false : undefined,
                        layoutConfig: undefined,
                        loadPageConfigError: true,
                    }
                })
            })
    }

    const getClassNames = (): string => {
        const classNames = ["generic-page"]
        if (state.isLoading) classNames.push("generic-page-loading")
        return classNames.join(" ")
    }

    /**
     * Runs once when the component is initialized
     */
    useEffect(() => {
        document.body.classList.add(BODY_CLASS_NAME)

        // cleanup function that will be executed when component is removed
        return function cleanup() {
            document.body.classList.remove(BODY_CLASS_NAME)
        }
    }, [])

    /**
     * Runs every time the user navigates to a new route and therefore, a new page config is supposed to be loaded and displayed
     */
    useEffect(() => {
        // load page config only if at least app context has advertiser id; while initializing the page the AppContext might not be
        // available yet and it is too soon to load tha page configuration at that point
        if (props.appContext?.advertiserId) {
            loadAndApplyPageConfig(props.pageConfiguration.path, props.appContext)
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pageConfiguration.path, props.appContext])

    return (
        <React.Fragment>
            {state.isLoading && <MainContentAreaLoadingMask withAdditionalOffset={true} />}
            <div className={getClassNames()}>
                {settingsAreValid && (
                    <AdditionalFilterContextProvider>
                        {state.layoutConfig && (
                            <LayoutRenderer
                                additionalCssClasses={["stretch-item", "generic-page-root-layout"]}
                                layoutElementConfig={state.layoutConfig as ContainerElementDTO}
                            />
                        )}
                    </AdditionalFilterContextProvider>
                )}
                {/* Show this message when there was an error during the loadPageConfig or when the layoutConfig is invalid. */}
                {/* loadPageConfig !== undefined must be checked to prevent a flashing error message while opening the page. */}
                {state.loadPageConfigError !== undefined && (state.loadPageConfigError || !settingsAreValid) && (
                    <div className="generic-page-error">
                        <PageErrorMessage
                            type={"info"}
                            title={"We’re sorry, but we were unable to load the requested data"}
                        >
                            <p>
                                {/* When the user is unauthorized then show an extra message. */}
                                {state.userAuthorized === false && (
                                    <span>
                                        Your user account might lack permissions to access this area. <br />
                                    </span>
                                )}
                                <span>
                                    If you have any questions about this, please reach out to our support team at{" "}
                                    <a href="mailto:support@exactag.com">support@exactag.com</a>.
                                </span>
                            </p>
                        </PageErrorMessage>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => ({ appContext: state.appContext.appContext })
export default connect(mapStateToProps)(GenericPage)
