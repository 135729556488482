import { Box, CircularProgress } from "@mui/material"
import { WidgetRenderer } from "domain/widget/WidgetRenderer"
import "gridstack/dist/gridstack.css"
import React, { Suspense, useLayoutEffect } from "react"
import { RemovableWidget } from "domain/widget/RemovableWidget"
import { getWidgetFormFieldKey, useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutBaseProvider"

export type DashboardLayoutItemProps = {
    x: number
    y: number
    w: number
    h: number
    id: string
}

export const DashboardLayoutItem = (props: DashboardLayoutItemProps) => {
    const { id, x, y, w, h } = props

    const { registerWidget, widgetForm: form, readOnly } = useDashboardLayout()
    useLayoutEffect(() => {
        const { unregisterWidget } = registerWidget(id)
        return unregisterWidget
    }, [id, registerWidget])

    return (
        <div className="grid-stack-item" gs-id={id} gs-x={x} gs-y={y} gs-w={w} gs-h={h}>
            <div className="grid-stack-item-content">
                <Suspense
                    fallback={
                        <Box
                            position="relative"
                            height="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CircularProgress />
                        </Box>
                    }
                >
                    <form.Field
                        name={getWidgetFormFieldKey(id)}
                        validators={{
                            // https://github.com/TanStack/form/issues/845
                            onMount: (field) => {
                                if (!field.fieldApi.state.meta.errorMap) {
                                    field.fieldApi.state.meta.errorMap = {}
                                }
                                return null
                            },
                        }}
                    >
                        {(field) => (
                            <RemovableWidget widgetId={id}>
                                <WidgetRenderer
                                    key={id}
                                    {...field.state.value}
                                    setSettings={
                                        readOnly
                                            ? undefined
                                            : (input: typeof field.state.value.settings) => {
                                                  if (field.state.value) {
                                                      const newValue = {
                                                          ...field.state.value,
                                                          settings: input,
                                                      }
                                                      field.handleChange(newValue)
                                                  }
                                              }
                                    }
                                />
                            </RemovableWidget>
                        )}
                    </form.Field>
                </Suspense>
            </div>
        </div>
    )
}
