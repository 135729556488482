import { useQueryClient } from "@tanstack/react-query"
import React, { PropsWithChildren, useCallback } from "react"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import { type DashboardDTO, LegacyDashboardConfigResponseDTO } from "generated/models"
import { useDashboardSettings } from "domain/dashboard/DashboardSettings/DashboardSettingsContext"
import { ColorManagementProvider } from "domain/widget/ColorManagementContext"
import { Box } from "@mui/material"
import { ReportingToggleContextProvider } from "domain/widget/ReportingToggleContext"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { log } from "shared/util/log"
import { DashboardLayoutBaseProvider } from "domain/dashboard/DashboardLayout/DashboardLayoutBaseProvider"
import { useMutationDashboardDTOToLegacy } from "domain/dashboard/hooks/useMutationDashboardDTOToLegacy"
import { useMutationDashboardDTOUpdate } from "domain/dashboard/hooks/useMutationDashboardDTOUpdate"
import { useMutationLegacyDashboardDTOToNew } from "domain/dashboard/hooks/useMutationLegacyDashboardDTOToNew"
import { useQueryDashboardsView } from "domain/dashboard/hooks/useQueryDashboardsView"
import { triggerDownloadDialogForLegacyDashboard } from "domain/dashboard/DashboardLayout/triggerDownloadDialogForLegacyDashboard"
import { removeFromWidgetForm } from "domain/dashboard/DashboardLayout/removeFromWidgetForm"
import { useDashboardLayoutForms } from "domain/dashboard/hooks/useDashboardLayoutForms"

export const EMPTY_DASHBOARD_UUID = "empty_dashboard_uuid"
export const LAYOUT_FORM_FIELD_KEY = "layout"

const emptyDashboardDTO: DashboardDTO = {
    widgets: {},
    layout: [],
    readOnly: false,
}

export const DashboardLayoutProvider = ({ children }: PropsWithChildren) => {
    const { uuid } = useDashboardSettings()
    const isEmptyDashboardUuid = uuid === EMPTY_DASHBOARD_UUID
    const queryClient = useQueryClient()

    const {
        dataDefinitions: { dimensions, metrics },
    } = useReportingConfigurationContext()

    /**
     * Updates the dashboard dto in the query cache
     */
    const setDashboardsViewQueryData = useCallback(
        (data: DashboardDTO) => {
            queryClient.setQueryData(["/api/reporting/dashboards/view", uuid], data)
        },
        [queryClient, uuid],
    )

    /**
     * Fetch the DashboardDTO data for the current uuid.
     * If the uuid is EMPTY_DACHBOARD_UUID, don't fetch the data and use the emptyDashboardDTO instead.
     * emptyDashboardDTO is used to show an empty dashboard when the user creates a new dashboard
     * or in the configuration tool.
     */
    const { data: dashboardsViewData, status: dashboardsViewStatus } = useQueryDashboardsView(
        uuid,
        isEmptyDashboardUuid ? emptyDashboardDTO : undefined,
        !isEmptyDashboardUuid,
    )

    /**
     * Save the dashboard
     */
    const { mutate: save, isPending: updateIsPending } = useMutationDashboardDTOUpdate(uuid, setDashboardsViewQueryData)

    /**
     * Convert the dashboard to the legacy format and trigger the download dialog
     */
    const { mutate: convertToLegacy, isPending: convertToLegacyIsPending } = useMutationDashboardDTOToLegacy(
        uuid,
        (legacyDashboard: LegacyDashboardConfigResponseDTO) =>
            triggerDownloadDialogForLegacyDashboard(legacyDashboard, `${uuid}.json`),
    )

    const { widgetForm, layoutForm } = useDashboardLayoutForms(dashboardsViewData)

    /**
     * Render the dashboard DTO
     */
    const renderDashboardDTO = useCallback(
        (dashboard: DashboardDTO) => {
            widgetForm.reset()
            layoutForm.reset()
            setDashboardsViewQueryData(dashboard)
        },
        [widgetForm, layoutForm, setDashboardsViewQueryData],
    )

    /**
     * Convert a legacy dashboard to the new format
     */
    const { mutate: convertLegacyToNew, isPending: convertLegacyToNewIsPending } = useMutationLegacyDashboardDTOToNew(
        uuid,
        renderDashboardDTO,
    )

    /**
     * Get the current dashboard configuration
     */
    const getCurrentDashboardConfig = useCallback(() => {
        return {
            ...dashboardsViewData,
            readOnly: dashboardsViewData?.readOnly ?? false,
            layout: layoutForm.state.values.layout,
            widgets: widgetForm.state.values.widgets,
        }
    }, [dashboardsViewData, layoutForm.state.values.layout, widgetForm.state.values.widgets])

    return (
        <DashboardLayoutBaseProvider
            readOnly={dashboardsViewData?.readOnly}
            updateIsPending={updateIsPending}
            convertToLegacyIsPending={convertToLegacyIsPending}
            convertLegacyToNewIsPending={convertLegacyToNewIsPending}
            onSubmit={() => save(getCurrentDashboardConfig())}
            onConvertToLegacy={() => convertToLegacy(getCurrentDashboardConfig())}
            convertLegacyToNew={convertLegacyToNew}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={(items) => layoutForm.setFieldValue(LAYOUT_FORM_FIELD_KEY, items)}
            pushLayout={(item) => layoutForm.pushFieldValue(LAYOUT_FORM_FIELD_KEY, item)}
            removeFromLayout={(id) => {
                const index = layoutForm.getFieldValue(LAYOUT_FORM_FIELD_KEY).findIndex((item) => item.id === id)
                if (index === -1) {
                    log.error(`Could not remove widget with id "${id}" because it wasn't found`)
                    return
                }
                layoutForm.removeFieldValue(LAYOUT_FORM_FIELD_KEY, index)

                removeFromWidgetForm(widgetForm, id)
            }}
        >
            {dashboardsViewStatus === "error" ? (
                // TODO: Add error component
                <Box></Box>
            ) : dashboardsViewStatus === "success" && dimensions.size > 0 && metrics.size > 0 ? (
                <ReportingToggleContextProvider reportingReplaceToggles={dashboardsViewData.reportingReplaceToggles}>
                    <ColorManagementProvider>{children}</ColorManagementProvider>
                </ReportingToggleContextProvider>
            ) : (
                <MainContentAreaLoadingMask withAdditionalOffset={true} />
            )}
        </DashboardLayoutBaseProvider>
    )
}
