import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { DashboardDTO, LegacyDashboardConfigResponseDTO } from "generated/models"
import * as api from "shared/service"
import UrlUtil from "shared/util/UrlUtil"
import UrlService from "shared/service/url.service"

export const useMutationLegacyDashboardDTOToNew = (
    uuid: string,
    onSuccess: (dashboard: DashboardDTO) => void,
): UseMutationResult<DashboardDTO, Error, LegacyDashboardConfigResponseDTO, unknown> => {
    return useMutation({
        mutationKey: ["/api/reporting/dashboards/convertLegacyToNew", uuid],
        mutationFn: (dashboard: LegacyDashboardConfigResponseDTO) => {
            return api.post(
                UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards/convertLegacyToNew"),
                dashboard,
            ) as Promise<DashboardDTO>
        },
        onSuccess: onSuccess,
    })
}
