import React from "react"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import ModalManager from "shared/component/modals/ModalManager"
import { ActionModal } from "shared/component/modals/action/ActionModal"
import { ActionModalContextProvider } from "shared/component/modals/action/ActionModalContext"
import { TOP_BAR_HEIGHT } from "layout/MainLayout/constants"

export const Main = () => {
    return (
        <Box
            className={"main"}
            component="main"
            sx={{
                flexGrow: 1,
                backgroundColor: "background.default",
                marginTop: TOP_BAR_HEIGHT,
            }}
        >
            <ActionModalContextProvider>
                <ActionModal />
                <ModalManager />
                <Outlet />
            </ActionModalContextProvider>
        </Box>
    )
}

export default Main
