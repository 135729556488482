import React from "react"
import { Box, Drawer } from "@mui/material"
import PerfectScrollbar from "react-perfect-scrollbar"
import CollapsedMenu from "layout/MainLayout/Menu/CollapsedMenu/CollapsedMenu"
import ExpandedMenu from "layout/MainLayout/Menu/ExpandedMenu/ExpandedMenu"
import { DRAWER_WIDTH_COLLAPSED, DRAWER_WIDTH_EXPANDED, TOP_BAR_HEIGHT } from "layout/MainLayout/constants"
import { styled } from "@mui/material/styles"
import { useDrawerContext } from "layout/MainLayout/Drawer/DrawerContext"
import { FloatingMenuContextProvider } from "layout/MainLayout/Menu/CollapsedMenu/FloatingMenuContextProvider"
import SidebarToggleButton from "./SidebarToggleButton"

const DrawerStyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ open }) => ({
    width: open ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED,
    "& .MuiDrawer-root": {
        width: open ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED,
    },
    "& .MuiDrawer-paper": {
        overflow: "hidden",
        marginTop: TOP_BAR_HEIGHT,
        width: open ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED,
        height: `calc(100vh - ${TOP_BAR_HEIGHT})`,
        zIndex: 1099,
    },
}))

export const SideBar = () => {
    const { isDrawerOpen, toggleDrawer } = useDrawerContext()

    return (
        <DrawerStyledBox component="nav" open={isDrawerOpen}>
            <Drawer
                variant="permanent"
                anchor="left"
                open={isDrawerOpen}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box sx={{ flexGrow: 1, position: isDrawerOpen ? "relative" : "static" }}>
                        {isDrawerOpen ? (
                            <PerfectScrollbar style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
                                <ExpandedMenu />
                            </PerfectScrollbar>
                        ) : (
                            <FloatingMenuContextProvider>
                                <CollapsedMenu />
                            </FloatingMenuContextProvider>
                        )}
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                        <SidebarToggleButton isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
                    </Box>
                </Box>
            </Drawer>
        </DrawerStyledBox>
    )
}

export default SideBar
