import { AdvertiserSelector } from "layout/MainLayout/AdvertiserSelector/AdvertiserSelector"
import React from "react"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { useNavigationContext } from "shared/NavigationContext"
import { AppBar as MuiAppBar, Box, IconButton, Toolbar, useTheme } from "@mui/material"
import UserMenu from "layout/MainLayout/UserMenu/UserMenu"
import { TOP_BAR_HEIGHT } from "layout/MainLayout/constants"
import { styled } from "@mui/material/styles"
import UrlService from "shared/service/url.service"
import { FONT_SIZE_BODY_XX_SMALL, FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"
import { BackendProvidedIcon } from "shared/component/BackendProvidedIcon"

export const TopBar = () => {
    const navigationContext = useNavigationContext()
    const { userConfigs } = useLayoutContext()

    let name = ""
    const firstName = userConfigs?.userInfo?.firstName
    const lastName = userConfigs?.userInfo?.lastName
    if (firstName && lastName) {
        name = `${firstName} ${lastName}`
    }

    const theme = useTheme()
    const onLogoClick = () => {
        navigationContext.navigate(UrlService.getUiContextPath())
    }

    return (
        <StyledMuiAppBar className={"top-bar"} enableColorOnDark position="fixed" elevation={0}>
            <Toolbar>
                <IconButton className={"logo-button"} onClick={onLogoClick}>
                    <BackendProvidedIcon />
                </IconButton>
                <AdvertiserSelector />
                <Box className={"spacer"} sx={{ flexGrow: 1 }} />
                <UserMenu name={name} loginName={userConfigs?.userInfo?.loginName} />
            </Toolbar>
        </StyledMuiAppBar>
    )
}

const StyledMuiAppBar = styled(MuiAppBar)(({ theme }) => ({
    overflow: "hidden",
    // needs to be less than the 1010 of .ant-message:
    zIndex: 1009,
    height: TOP_BAR_HEIGHT,
    "& .MuiToolbar-root": {
        justifyContent: "space-between",
        backgroundColor: theme.palette.topBar.backgroundColor,
        color: theme.palette.topBar.color,
        padding: "0 6px",
        height: TOP_BAR_HEIGHT,
        minHeight: TOP_BAR_HEIGHT,
    },
    "& .MuiIconButton-root": {
        margin: 0,
        padding: theme.spacing(1),
        color: theme.palette.topBar.color,
    },
    "& .MuiSvgIcon-root": {
        width: "18px",
    },
    "& .MuiBadge-badge": {
        border: "2px solid " + theme.palette.topBar.backgroundColor,
        fontSize: FONT_SIZE_BODY_XX_SMALL,
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
    },
    "& .advertiser-selector": {
        height: TOP_BAR_HEIGHT,
        marginLeft: "-6px",
    },
}))

export default TopBar
