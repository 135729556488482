import React from "react"
import moment from "moment"
import { Col, Row } from "antd"
import { ScenarioInfoBox } from "domain/adspend-optimizer/component/overview/ScenarioInfoBox"
import { Scenario } from "domain/types"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    SliceData,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { ReportingDataSetDTO } from "generated/models"

const dateFormat: string = "DD.MM.YYYY"

type Props = {
    scenario1: Scenario
    scenario2: Scenario
    selectScenario1?: (dimensionId: string, scenarioId: number) => void
    selectScenario2?: (dimensionId: string, scenarioId: number) => void
    comparisonData?: ReportingDataSetDTO
    scenarios?: Scenario[]
}

export const ScenarioInfoBoxes = (props: Props) => {
    const getScenarioName = (scenario) => {
        if (!scenario) {
            return ""
        }
        return scenario.adspend_scenario_name
    }

    const isDefaultScenario = (scenario: Scenario) => {
        return typeof scenario.adspend_scenario_id === "string"
    }

    const getScenarioTotalData = (scenario: Scenario, comparisonData: ReportingDataSetDTO): SliceData => {
        return (scenario &&
            comparisonData &&
            comparisonData.totals.slices.data.find(
                (s) =>
                    s.filter[
                        DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER))
                    ] == scenario.adspend_scenario_id,
            )) as SliceData
    }

    const getScenarioPeriodInfo = (scenario1: Scenario, scenario2: Scenario) => {
        if (!scenario1 || !scenario2) {
            return ""
        }

        const createPeriodInfo = (start, end) => {
            const diff = moment.parseZone(end).add(1, "day").diff(moment.parseZone(start), "week")
            const weekExt = diff == 1 ? "week" : "weeks"
            const startDateFormatted = moment.parseZone(start).format(dateFormat)
            const endDateFormatted = moment.parseZone(end).format(dateFormat)
            return `${startDateFormatted} to ${endDateFormatted} (${diff} ${weekExt})`
        }

        if (scenario1.start_date) {
            return createPeriodInfo(scenario1.start_date, scenario1.end_date)
        } else if (isDefaultScenario(scenario1)) {
            if (scenario2 && scenario2.start_date) return createPeriodInfo(scenario2.start_date, scenario2.end_date)
            else {
                const fromDate = moment().local().startOf("isoWeek").subtract(1, "week")
                const toDate = moment().local().endOf("isoWeek").subtract(1, "week")
                return createPeriodInfo(fromDate.format("YYYY-MM-DD"), toDate.format("YYYY-MM-DD"))
            }
        } else return ""
    }

    return (
        <React.Fragment>
            <Row justify="center">
                <Col span={12}>
                    <ScenarioInfoBox
                        introText={"Comparing"}
                        scenarioName={getScenarioName(props.scenario1)}
                        scenarioTotalData={getScenarioTotalData(props.scenario1, props.comparisonData)}
                        scenarioPeriodInfo={getScenarioPeriodInfo(props.scenario1, props.scenario2)}
                        scenarioJobStatus={props.scenario1 ? props.scenario1.job_status : ""}
                        scenarioSelector={props.selectScenario1}
                        scenarios={props.scenarios}
                    />
                </Col>
                <Col span={12}>
                    <ScenarioInfoBox
                        className={"dark-box"}
                        introText={"with"}
                        scenarioName={getScenarioName(props.scenario2)}
                        scenarioTotalData={getScenarioTotalData(props.scenario2, props.comparisonData)}
                        scenarioPeriodInfo={getScenarioPeriodInfo(props.scenario2, props.scenario1)}
                        scenarioJobStatus={props.scenario2 ? props.scenario2.job_status : ""}
                        scenarioSelector={props.selectScenario2}
                        scenarios={props.scenarios}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}
