import React, { createContext, PropsWithChildren, useContext } from "react"
import { useGridStackIntegration } from "domain/dashboard/DashboardLayout/useGridStackIntegration"
import { WidgetMap, WidgetProps } from "domain/widget/WidgetRenderer"
import { DashboardLayoutItemProps } from "domain/dashboard/DashboardLayout/DashboardLayoutItem"
import { nanoid } from "nanoid"
import { usePrintHtml } from "domain/widget/hooks/usePrintHtml"
import { FormApi, ReactFormApi, Validator } from "@tanstack/react-form"
import { DashboardDTO, LegacyDashboardConfigResponseDTO } from "generated/models"

type Form<TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined> = FormApi<
    TFormData,
    TFormValidator
> &
    ReactFormApi<TFormData, TFormValidator>

export type WidgetValues = Pick<DashboardDTO, "widgets">
export type LayoutValues = Pick<DashboardDTO, "layout">
export type WidgetForm = Form<WidgetValues>
export type LayoutForm = Form<LayoutValues>

export type GridStackConfig = {
    disableDrag?: boolean
    disableResize?: boolean
    readOnly?: boolean
}

type DashboardLayoutBaseProviderProps = GridStackConfig & {
    updateIsPending: boolean
    convertToLegacyIsPending: boolean
    convertLegacyToNewIsPending: boolean
    widgetForm: WidgetForm
    layoutForm: LayoutForm
    onSubmit: () => void
    onConvertToLegacy: () => void
    convertLegacyToNew: (legacyDashboard: LegacyDashboardConfigResponseDTO) => void
    setLayout: (value: DashboardLayoutItemProps[]) => void
    pushLayout: (value: DashboardLayoutItemProps) => void
    removeFromLayout: (id: string) => void
    readOnly?: boolean
}

export type DashboardLayoutProviderType = ReturnType<typeof useGridStackIntegration> & {
    widgetForm: WidgetForm
    layoutForm: LayoutForm
    updateIsPending: boolean
    convertToLegacyIsPending: boolean
    convertLegacyToNewIsPending: boolean
    onConvertToLegacy: () => void
    submit: () => void
    addWidget: <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => void
    removeWidget: (id: string) => void
    htmlToImage: ReturnType<typeof usePrintHtml>
    readOnly: boolean
    convertLegacyToNew: (legacyDashboard: LegacyDashboardConfigResponseDTO) => void
}

export const DashboardLayoutContext = createContext<DashboardLayoutProviderType | undefined>(undefined)

/**
 * Gets the key used to store a widget in the widget form
 * @param id
 */
export const getWidgetFormFieldKey = (id: string): `widgets.${string}` => `widgets.${id}`

export const DashboardLayoutBaseProvider = (props: PropsWithChildren<DashboardLayoutBaseProviderProps>) => {
    const {
        disableDrag,
        disableResize,
        readOnly,
        widgetForm,
        layoutForm,
        setLayout,
        pushLayout,
        removeFromLayout,
        children,
        onSubmit,
        onConvertToLegacy,
        updateIsPending,
        convertToLegacyIsPending,
        convertLegacyToNewIsPending,
        convertLegacyToNew,
    } = props

    const gridstackIntegration = useGridStackIntegration({
        onUpdate: setLayout,
        disableDrag,
        disableResize,
        readOnly: readOnly ?? true,
    })

    const addWidget = <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => {
        const id = nanoid(10)
        widgetForm.setFieldValue(getWidgetFormFieldKey(id), { ...widget, id })
        pushLayout({
            id,
            h: 4,
            w: 4,
            x: 0,
            y: 0,
            ...options,
        })
    }

    const htmlToImage = usePrintHtml()

    return (
        <DashboardLayoutContext.Provider
            value={{
                ...gridstackIntegration,
                updateIsPending,
                convertToLegacyIsPending,
                convertLegacyToNewIsPending,
                widgetForm,
                layoutForm,
                addWidget,
                convertLegacyToNew: convertLegacyToNew,
                removeWidget: removeFromLayout,
                onConvertToLegacy: onConvertToLegacy,
                htmlToImage,
                submit: onSubmit,
                readOnly: readOnly ?? true,
            }}
        >
            {children}
        </DashboardLayoutContext.Provider>
    )
}

export const useDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    if (context === undefined) {
        throw new Error("useDashboardLayout must be used within a DashboardLayoutProvider")
    }
    return context
}

export const useHasParentDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    return context !== undefined
}
