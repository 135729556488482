import * as React from "react"
import { CustomDialog } from "layout/components/CustomDialog"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"
import PopupTextUtil from "shared/util/PopupTextUtil"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "shared/redux/store"
import { closeActionModal } from "shared/component/modals/action/ActionModal.slice"
import { getStatusId } from "shared/util/util"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { DataRowDTO } from "domain/types"
import ActionService, { DIMENSION_STATUS } from "shared/service/ActionService"
import { useActionModalContext } from "shared/component/modals/action/ActionModalContext"

export const ActionModal = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { setForceUpdate } = useActionModalContext()
    const [submitting, setSubmitting] = React.useState(false)
    const { actionModalConfig } = useSelector((state: RootState) => state.actionModal)
    const { current: id } = React.useRef(getUniqueId("action-modal"))

    const onClose = () => {
        dispatch(closeActionModal())
    }
    const onSubmit = () => {
        const {
            mainDimension: { identifier },
            action,
            selectedItems,
            apiPath,
        } = actionModalConfig
        const statusId = getStatusId(action.type)
        const submitData = selectedItems.map((row) => {
            return {
                [DimensionService.getValueColumn(asDataColumnIdentifier(identifier))]: row[identifier].value,
                [DimensionService.getValueColumn(asDataColumnIdentifier(DIMENSION_STATUS))]: statusId,
            } as DataRowDTO
        })

        setSubmitting(true)
        ActionService.statusPopupOnConfirm(submitData, `${apiPath}/updatedata`).finally(() => {
            setSubmitting(false)
            // update the state so that child elements (e.g. GenericDataGridWidget) can react to that change
            setForceUpdate((prev) => !prev)
            dispatch(closeActionModal())
        })
    }

    const header = actionModalConfig
        ? PopupTextUtil.createPopupHeader(
              actionModalConfig.action,
              actionModalConfig.selectedItems,
              actionModalConfig.mainDimension,
          )
        : ""
    const content = actionModalConfig ? (
        PopupTextUtil.createPopupItemSentence(
            actionModalConfig.action,
            actionModalConfig.selectedItems,
            actionModalConfig.mainDimension,
        )
    ) : (
        <></>
    )

    return (
        <CustomDialog
            open={Boolean(actionModalConfig)}
            key={id}
            onClose={onClose}
            footer={{
                kind: "yesNoButton",
                noText: "No",
                yesText: "Yes",
                onYesButtonClick: onSubmit,
                onNoButtonClick: onClose,
                submitting: submitting,
            }}
            content={content}
            header={header}
            modalMinHeight={50}
            modalWidth={500}
        />
    )
}
