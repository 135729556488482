import { LegacyDashboardConfigResponseDTO } from "generated/models"
import { triggerDownloadDialog } from "utils/download"

export const triggerDownloadDialogForLegacyDashboard = (
    legacyDashboard: LegacyDashboardConfigResponseDTO,
    fileName: string,
) => {
    // Convert the JSON object to a string
    const jsonString = JSON.stringify(legacyDashboard)

    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: APPLICATION_JSON })
    triggerDownloadDialog(blob, fileName)
}

const APPLICATION_JSON = "application/json"
