import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { DashboardDTO } from "generated/models"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"

export const useMutationDashboardDTOUpdate = (
    uuid: string,
    onSuccess: (dashboard: DashboardDTO) => void,
): UseMutationResult<DashboardDTO, Error, DashboardDTO, unknown> => {
    return useMutation({
        mutationKey: ["/api/reporting/dashboards/update", uuid],
        mutationFn: (dashboard: DashboardDTO) => {
            return api.post(
                `${UrlService.getReportingServiceApiUrl()}/dashboards/update`,
                dashboard,
            ) as Promise<DashboardDTO>
        },
        onSuccess: onSuccess,
    })
}
