import React, { useMemo } from "react"
import { FloatingMenuListItem } from "layout/MainLayout/Menu/CollapsedMenu/FloatingMenuListItem"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import { styled } from "@mui/material/styles"
import { BORDER_RADIUS_SMALL, FONT_WEIGHT_BOLD, FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"
import { MenuLeafDTO } from "domain/types"
import { findPrintablePathInTree } from "layout/MainLayout/Menu/menuUtils"
import { MenuRenderer } from "layout/MainLayout/Menu/MenuRenderer"
import { CommonMenuPaper } from "layout/MainLayout/Menu/CommonMenuPaper"

export const CollapsedMenu = () => {
    const { userConfigs, navigate, pathname } = useLayoutContext()
    const menu = useMemo(() => userConfigs.menu ?? { root: [] }, [userConfigs])
    const analyticsService = useAnalytics()

    const makeLeafClickHandler = (path: string, event: React.MouseEvent) => {
        event.preventDefault()
        navigate(path)
        const printablePath = findPrintablePathInTree(path, menu.root)
        analyticsService
            .trackMenuNavigation(printablePath[0], printablePath.join(" / "))
            .catch((err) => console.debug("Failed to track menu navigation", err))
    }

    const renderLeaf = (level: number, item: MenuLeafDTO) => (
        <FloatingMenuListItem
            key={item.title}
            type="leaf"
            menuLeafDTO={item}
            level={level}
            isSelected={pathname.indexOf(item.path) >= 0}
            globalOnClickHandler={makeLeafClickHandler}
        />
    )

    const renderNode = (level: number, item: any, _children: React.ReactNode | null) => (
        <FloatingMenuListItem
            key={item.title}
            type="node"
            menuNodeDTO={item}
            level={level}
            globalOnClickHandler={makeLeafClickHandler}
        />
    )

    return (
        <StyledPaper elevation={0} className="main-menu collapsed-menu">
            <MenuRenderer
                level={0}
                menuItems={menu.root}
                dense={false}
                recursiveNodes={false}
                renderLeaf={renderLeaf}
                renderNode={renderNode}
            />
        </StyledPaper>
    )
}

const StyledPaper = styled(CommonMenuPaper)(({ theme }) => ({
    "& .MuiListItemButton-root": {
        borderRadius: BORDER_RADIUS_SMALL,
        "&:hover": {
            backgroundColor: theme.palette.primaryShades[50],
            // backgroundColor: "#F8F9FE",
        },
    },
    "& .MuiTypography-subtitle2": {
        color: theme.palette.secondaryTitleColor,
        fontSize: "13px!important",
        padding: "0 0 10px 10px",
        fontWeight: FONT_WEIGHT_BOLD,
    },
    "& .selected > .MuiListItemButton-root": {
        marginRight: "0px",
        marginLeft: "0px",
        backgroundColor: theme.palette.primaryShades[50],
    },
    "& .selected span": {
        color: theme.palette.primary.main,
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
    },
    "&& .level-0 > .Mui-selected": {
        backgroundColor: theme.palette.primary.main + "!important",
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.contrastText,
        },
    },
    "&& .level-0 > .MuiListItemButton-root": {
        padding: "0 0 0 10px",
        margin: "0 3px",
        borderRadius: BORDER_RADIUS_SMALL,
    },
}))

export default CollapsedMenu
